import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
const RefundPolicy = () => {
    return(
    <>
        <section className="customer-support bg-header">
            <div className="bg-page-breadcrumb">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="12" lg="12" sm="12" xs="12">
                            <h4 className="page-breadcrumb-title">Refund and Cancellation Policy</h4>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                </div>
        </section>
        <section className="inside-page-content">
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12" lg="12" sm="12" xs="12">
                        <div className="page-content-block">
                            <h4 className="page-title">Refund policy</h4>
                            <p className="mt-3">If you cancel an active subscription before the due date, the remaining days payment of the subscription charges will be refunded.</p>
                        </div>
                        <div className="page-content-block mt-5">
                            <h4 className="page-title">Cancellation policy</h4>
                            <p className="mt-3">Effective date of cancellation will depend on the type of membership. Please send a written request for cancellation to vinod@dias.works</p>
                        </div>
                        <div className="page-content-block mt-5">
                            <h4 className="page-title">Cancellation formalities include</h4>
                            <p className="mt-3">Cancelling the membership.</p>
                            <p className="mt-3">Deletion of all personal data and credit card information on the day the account is deactivated</p>
                            <p className="mt-3">Exit interview for feedback to help us improve our product</p>
                            <p className="mt-3">Please note we will never share your information with third party vendors
                            Confirmation email with the effective date of cancellation based on the subscription type.</p>
                        </div>
                        <div className="page-content-block mt-5">
                            <h4 className="page-title">Monthly Subscription </h4>
                            <p className="mt-3">Charges will terminate and account will be deactivated the following month after the written request is received</p>
                        </div>
                        <div className="page-content-block mt-5">
                            <h4 className="page-title">Yearly subscription</h4>
                            <p className="mt-3">Membership will terminate and account will be deactivated on the annual renewal date</p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    </>
    )
}
export default RefundPolicy;